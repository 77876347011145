<script>
  import Brand from "./Brand.svelte";
  import Left from "./Left.svelte";
  import Right from "./Right.svelte";
</script>

<Brand />

<div class="container mx-auto max-w-5xl">
  <div class="p-12">
  </div>
  <div class=" grid grid-cols-1 lg:grid-cols-5 p-8 gap-4">
    <div class="bg-white shadow-lg text-black col-span-1 lg:col-span-2 p-4">
      <Left />
    </div>

    <div class=" bg-white shadow-lg text-black col-span-1 lg:col-span-3 p-4">
      <Right />
    </div>
  </div>
</div>

<style global lang="postcss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
