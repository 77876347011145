<script>
  import { onMount } from "svelte";
  import Icons from "./Icons.svelte";

  import "./finneyfor.css";

  const texts = [
    `Transactions are secure in a separate hot wallet. Your customers keep their own private keys. You don't have to add any complicated security protocols to your server (it even works with ultra secure static sites).`,
    `Collecting payments does not require your users to have anything other than a regular web browser, and it works perfectly on mobile. No special software or hardware is required.`, `Your customers can pay anywhere in the world as long as they have an internet connection. No bank account or credit card is required, and no limitations are placed on their location or nationality.`,
    `FinneyFor collects no fees. (Using a combination of smart contracts and L2 rollup technologies, fees are much smaller than $0.01 USD per transaction)`,
    `You collect money on your own site. Don't send your readers to someone else's site and risk losing your identity if you change payment providers.`,
  ];

  const notes = ["secure", "universal", "global", "feefree", "identity"];

  let text;
  let interval;
  let index = 0;
  let textIndex = 0;
  let wait = 0;
  onMount(() => {
    selected = notes[index];
    interval = setInterval(() => {
      if (wait > 0) {
        wait -= 1;
      } else {
        textIndex += parseInt(Math.random() * 5, 10);
        text = texts[index].slice(0, textIndex);
        if (textIndex > texts[index].length) {
          index = (index + 1) % notes.length;
          selected = notes[index];
          textIndex = 0;
          wait = 10;
        }
      }
    }, 100);
  });

  let selected;
</script>

<div class="grid">
  <h1 class="header text-xl pb-8">
    FinneyFor.com facilitates
    <span class:focused={selected === "secure"} class="highlight secure"
      >secure</span
    >,
    <span class:focused={selected === "universal"} class="highlight universal"
      >universal</span
    >,
    <span class:focused={selected === "global"} class="highlight global"
      >global</span
    >,
    <span class:focused={selected === "feefree"} class="highlight feeFree"
      >fee-free</span
    >
    payments between your customers and lets you fully own your
    <span class:focused={selected === "identity"} class="highlight identity"
      >identity and brand</span
    >.
  </h1>

  <div class="flex flex-row">
    <div class="p-8">
  <Icons/>
  </div>
  <div class="typing text-sm lg:text-lg">
      <span id="text" />
      <div class="">
        {text}
        <span class:myhide={index % 2 === 0}>|</span>
      </div>
    </div>
  </div>
</div>

<style>
  .typing {
    min-height: 20rem;
  }
  .myhide {
    @apply hidden;
  }
  .selected {
    @apply underline;
  }
  #text {
    @apply text-sm md:text-lg;
  }
</style>
