<div
  class="
  bg-black  
  p-8 
  text-white 
    w-full
    mx-auto 
    flex
    "
>
<div class="mx-auto w-full md:w-1/2">
  <a
    class="
          flex
          items-center
          no-underline
          hover:no-underline
          font-bold
          text-2xl
        w-full
          "
    href="#"
  >
    FinneyFor: ethereum based microtransactions for Internet creators
  </a>

  <div class=" p-4">
    &copy; FinneyFor 2021, Patent Pending
    <a class="underline" href="mailto:chris@finneyfor.com"> Contact us </a>
  </div>
</div>
</div>
