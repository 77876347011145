<script>
  let mode = undefined;

  const svekyll = '<s' + 'cript' + `>
import FinneyFor from 'finneyfor';
</s` + `cript>

<slot/>
<FinneyFor/>
`;

  const jekyll = `
  <div id="excerpt">
  {{ page.excerpt }}
</div>
<div id="full" style="display: none">
  {{ content }}
</div>

<iframe src="https://tx.finneyfor.com/contribution/poetry.finneyfor.io">
  height="150px" width="100%" frameborder="0" id="th"
</iframe>
`;

  const finneyScript = `
  FinneyforClient.onContribution(function () {
    revealContent();
  });
  function setDisplayById(id, display) {
    const el = document.getElementById(id);
    if (el) {
      el.style.display = display;
    }
  }
  function revealContent() {
    setDisplayById("excerpt", "none");
    setDisplayById("full", "inline-block");
    setDisplayById("th", "none");
  }
  `;
</script>

<div class="w-full">
  <div class="flex flex-col">
    <div class="text-lg">
      FinneyFor works with any website or blog, no server components required.
    </div>

    <div class="py-8 ">
      <div class="flex flex-row justify-around">
        <button on:click={() => (mode = "jekyll")} class="btn text-sm">Jekyll</button>
        <button on:click={() => (mode = "svekyll")} class="btn text-sm">Svekyll</button>
        <button on:click={() => (mode = "wordpress")} class="btn text-sm">Wordpress</button>  
      </div>
    </div>

    {#if mode === "wordpress"}
      <div class="pb-8">
        To collect Ethereum on your Wordpress blog just use our Wordpress plugin:

        <a class="underline py-8 text-lg" href="https://extrastatic.dev/finneyfor/finneyfor-wp-plugin">
            FinneyFor Wordpress Plugin
          </a>
      </div>
    {/if}

    {#if mode === "jekyll"}
      <div class="pb-8">
        To collect Ethereum on your Jekyll blog, just add this to
        your post.html file.
      </div>

      <div class="mockup-code">
        <pre
          class="p-4">
          <code>
            { jekyll  + '<script>' + finneyScript + '</script>' }
          </code>
          </pre>
      </div>
    {/if}

    {#if mode === "svekyll"}
      <div class="pb-8">
        To collect Ethereum on your Svekyll blog, just add this to your
        blog.svelte layout file.
      </div>

      <div class="mockup-code">
        <pre
          class="p-4">
          <code>{ svekyll }</code>
          </pre>
      </div>
    {/if}
  </div>
</div>

<style>
  a {
  @apply underline;
}

</style>